import api from "./index"

/**
 * change post monetization
 *
 * @param id
 * @param value
 * @return {*}
 */
export const changePostMonetization = (id, value ) => {
    return api
        .request(`admin/post/monetization`)
        .withBody({
            post_id: id,
            value: value
        })
        .post()
}

/**
 * change user monetization
 *
 * @param id
 * @param value
 * @return {*}
 */
export const changeUserMonetization = (id, value ) => {
    return api
        .request(`admin/user-monetization`)
        .withBody({
            user_id: id,
            value: value
        })
        .post()
}

/**
 *  user Profit
 *
 * @param params
 * @return {*}
 */
export const userPeriodRequest = params => {
    return api
        .request('admin/user-profit')
        .withParams(params)
        .get()
}